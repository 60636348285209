<template>
  <main class="onboarding__main tw-flex tw-flex-col tw-items-center">
    <header
      class="tw-h-20 tw-w-full tw-items-center tw-flex tw-justify-between tw-px-3 md:tw-px-8 md:tw-px-[50px] tw-bg-[#FAFBFF] tw-mb-6"
    >
      <a
        href="http://getequity.io"
        target="_blank"
        rel="noopener noreferrer"
        :class="{ 'tw-mx-auto': organisationDetails?.email }"
      >
        <img
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1654256869/getequityV2/GetEquity_Logo_1_w9teqh.svg"
          alt="GetEquity Logo"
        />
      </a>
      <button
        v-if="!organisationDetails?.email && $route.name !== 'SignIn'"
        class="tw-bg-primary tw-leading-[140%] tw-font-normal tw-text-white tw-text-base tw-rounded tw-w-36 tw-block button"
      >
        <div class="tw-flex tw-gap-x-3 tw-items-center tw-justify-center">
          Sign In
          <img
            class="button__image"
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1656410547/getequityV2/Icons_ih8h4g.svg"
          />
        </div>
        <nav class="button__nav tw-absolute">
          <div
            class="tw-h-14 tw-py-4 tw-px-5 tw-flex tw-justify-center tw-border-b tw-border-gray-light"
          >
            <a
              :href="
                windowLocation.origin.includes('getequity.io')
                  ? 'https://app.getequity.io/onboarding/signin'
                  : 'https://staging--getequityinvestors.netlify.app/onboarding/signin'
              "
              target="_blank"
              class="tw-text-sm tw-font-medium tw-text-secondary"
              rel="noopener noreferrer"
              >Sign in as an Investor</a
            >
          </div>
          <div
            class="tw-h-[56px] tw-py-4 tw-px-5 tw-flex tw-justify-center tw-border-b tw-border-[#f0f4fd]"
          >
            <a
              class="tw-text-sm tw-font-medium tw-text-secondary"
              :href="
                windowLocation.origin.includes('getequity.io')
                  ? 'https://founders.getequity.io/onboarding/account-type'
                  : 'https://staging--company-dealroom.netlify.app/onboarding/account-type'
              "
              target="_blank"
              >Sign in as a Founder</a
            >
          </div>
          <div class="tw-h-[56px] tw-py-4 tw-px-5 tw-flex tw-justify-center">
            <router-link
              class="tw-text-sm tw-font-medium tw-text-secondary"
              :to="{ name: 'SignIn' }"
              >Sign in as an Institution</router-link
            >
          </div>
        </nav>
      </button>
      <router-link
        v-if="$route.name === 'SignIn'"
        :to="{ name: 'DataCollection' }"
        class="tw-bg-primary tw-leading-[140%] tw-font-normal tw-text-white tw-text-base tw-rounded tw-w-36 tw-flex tw-items-center tw-justify-center button"
        >Sign up
      </router-link>
    </header>
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <component :is="Component" class="tw-px-4" />
      </transition>
    </router-view>
  </main>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getItem } from "@/utils/storage.js";
  export default {
    name: "OnboardingView",
    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
      organisationDetails() {
        return getItem("org_details");
      },
      windowLocation() {
        return window.location;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .onboarding {
    &__main {
      @include fadeIn;
      background-color: #fff;
      min-height: 100vh;
    }
  }
  .button {
    transition: transform 1s ease-in-out;
    overflow: hidden;
    height: 45px;
    &:hover {
      position: relative;
      overflow: visible;
      .button__image {
        transition: transform 1s ease-in-out;
        transform: rotate(180deg) translateY(-2px);
      }
      .button__nav {
        bottom: 10px;
        top: 60px;
        opacity: 1;
        right: 0;
        z-index: 10;
      }
    }
    &__nav {
      top: -160px;
      right: 0;
      opacity: 0;
      background-color: #fff;
      border: 1px solid #e1e6f0;
      transition: all 800ms ease-in-out;
      box-shadow: 0px 2px 25px rgba(11, 40, 105, 0.15);
      border-radius: 4px;
      width: 200px;
      height: 166px;
    }
  }
</style>
